hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 20px;
    margin-top: 20px !important;
    @media(max-width: 700px) {
        padding-bottom: 10px;
        margin-top: 10px !important;
    }
}